<template>
    <div class="welcome">
        <img src="@/assets/wizard/welcome-new.png" srcset="@/assets/wizard/welcome-new@2x.png 2x" alt=""/>
        <h1 v-if="user">Willkommen, {{ guestFirstName() }}</h1>
        <h1 v-else>Geschafft!</h1>
        <p>Dein Account wurde erfolgreich angelegt.<br>Viel Spaß beim Entdecken unserer Playlists, Workouts und Magazin-Inhalte!</p>
        <v-btn class="button button--primary" to="/trackinghint">Los geht's</v-btn>
    </div>
</template>

<script>
import firebase from 'firebase';

export default {
    name: "Welcome",
    mounted() {
        this.init()
    },
    methods: {
        init() {
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    this.user = firebase.auth().currentUser;
                }
            });
        },
        guestFirstName() {
            if (this.user) {
                return this.user.displayName.split(" ")[0];
            }
        }
    },
    data() {
        return {
            user: null,
        }
    }
}
</script>

<style lang="scss">
    .welcome {
        display: flex;
        flex-direction: column;
        text-align: center;
        min-height: calc(100% + 68px);
        margin-top: -68px;

        img {
            width: 100%;
            max-width: 292px;
            margin: auto auto 6px;
        }

        p {
            margin-top: 7px;
            font-weight: 500;
            margin-bottom: auto;
            opacity: 1;
            color: rgba($colorBlack, .64);

            span {
                font-weight: 600;
                color: $colorBlack;
            }
        }

        .button--secondary {
            font-size: 17px !important;
            padding: 20px 8px !important;
        }
    }
</style>